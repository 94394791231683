import React from 'react'

import Layout from '../components/layout'
import SecondaryPageHero from '../components/secondaryPageHero'

import forBizHeroImg from '../images/hero-press.jpg'
import forBizSvg from '../images/icon-camera.svg'

import pressMain from '../images/press-logo-ip.svg'

import pressOne from '../images/press-logo-iowa-pharmacy-horiz.svg'
import pressThree from '../images/press-logo-pt.svg'
import pressTwo from '../images/press-logo-cbj.svg'

import pressFive from '../images/press-logo-su.svg'
import pressSix from '../images/press-logo-medquarter-bw.svg'

import pressSeven from '../images/press-logo-dub.svg'
import pressNine from '../images/press-logo-lv.svg'

const Typography = () => (
  <Layout>
    <SecondaryPageHero
      heroImg={forBizHeroImg}
      iconImg={forBizSvg}
      iconImgAlt={'Industrial Building'}
      title={'OUR'}
      accentedTitle={'Press'}
      subtitle={
        'News stories and articles about the grassroots movement'
      }
    />

    <section className="section mow-border-bottom" key="mow-forbiz-graphic">
      <div className="container">
        <div className="columns">
          <div className="column is-10 center-block">

            <div className="columns">
              <div className="column is-4">
                <img src={pressMain} alt="Iowa Public Radio River to River" className="press-logo"/>
              </div>
              <div className="column">
                <blockquote>It’s a movement to promote ‘Covid-Conscious’ businesses in order to improve health, and the wealth; because when the businesses thrive, of course, your community creates wealth. We want to get back to that position we’ve had of a strong economy in our communities across the nation.
                  <figcaption>
                    <span className="blockquote-author">Ben Kieffer</span>
                    <span>, River to River Host</span>
                  </figcaption>
                </blockquote>
                <span className="link-arrow"><a href="https://podcasts.apple.com/us/podcast/reopening-with-a-conscious-approach/id635968894?i=1000476930751" target="_blank">Listen</a></span>
              </div>
            </div>

            <div class="anchor-border press-lower">
            </div>



            <div className="columns press-row">

              <div className="column">
                <a href="https://pharmacy.uiowa.edu/article/alumnus-and-students-help-enhance-safety-businesses-amid-covid-19-pandemic" target="_blank" className="press-logo no-border-bottom">
                  <img src={pressOne} alt="University of Iowa"/>
                </a>
                <p>Alumnus and Students Help Enhance Safety of Businesses Amid COVID-19 Pandemic</p>
                <span className="link-arrow"><a href="https://pharmacy.uiowa.edu/article/alumnus-and-students-help-enhance-safety-businesses-amid-covid-19-pandemic" target="_blank">Read More</a></span>
              </div>

              <div className="column">
                <br />
                <a href="https://www.corridorbusiness.com/doctors-to-launch-mask-of-wellness-program-today/" target="_blank" className="press-logo no-border-bottom">
                  <img src={pressTwo} alt="Corridor Business Journal" className="press-logo"/>
                </a>
                <p>Doctors to launch Mask of Wellness program</p>
                <span className="link-arrow"><a href="https://www.corridorbusiness.com/doctors-to-launch-mask-of-wellness-program-today/" target="_blank">Read More</a></span>
              </div>

              <div className="column">
                <a href="https://www.pharmacytimes.com/interviews/mask-of-wellness-campaign-encourages-masks-cleanliness-in-local-businesses" target="_blank" className="press-logo no-border-bottom">
                  <img src={pressThree} alt="Pharmacy Times"/>
                </a>
                <p>Campaign Encourages Masks, Cleanliness in Local Businesses</p>
                <span className="link-arrow"><a href="https://www.pharmacytimes.com/interviews/mask-of-wellness-campaign-encourages-masks-cleanliness-in-local-businesses" target="_blank">Read More</a></span>
              </div>

            </div>



            <div className="columns press-row">
              <div className="column">
                <a href="https://cbs2iowa.com/news/local/iowa-city-doctors-to-launch-covid-19-prep-program-for-businesses-set-to-reopen" target="_blank" className="press-logo no-border-bottom">
                  <h3>CBS2</h3>
                  <h5>News</h5>
                </a>
                <p>Doctors to launch COVID-19 prep program for businesses set to reopen</p>
                <span className="link-arrow"><a href="https://cbs2iowa.com/news/local/iowa-city-doctors-to-launch-covid-19-prep-program-for-businesses-set-to-reopen" target="_blank">Read More</a></span>
              </div>

              <div className="column">
                <a href="https://www.youtube.com/watch?v=RxmuswlXyvk&t=13s" target="_blank" className="press-logo no-border-bottom">
                  <img src={pressFive} alt="Stand Up podcast" className="press-logo"/>
                </a>
                <p>115 Dean Obeidallah, Dr. Dave Krupp, Christian Finnegan</p>
                <span className="link-arrow"><a href="https://www.youtube.com/watch?v=RxmuswlXyvk&t=13s" target="_blank">Read More</a></span>
              </div>

              <div className="column">
                <a href="https://kwwl.com/2020/05/01/health-care-workers-educate-businesses-on-safely-reopening/" target="_blank" className="press-logo no-border-bottom">
                  <h3>KWWL</h3>
                  <h5>News</h5>
                </a>
                <p>Health care workers educate businesses on safely reopening</p>
                <span className="link-arrow"><a href="https://kwwl.com/2020/05/01/health-care-workers-educate-businesses-on-safely-reopening/" target="_blank">Read More</a></span>
              </div>
            </div>

            <div className="columns press-row">
              {/*<div className="column">
                <a href="#" target="_blank" className="press-logo no-border-bottom">
                  <h3>Linn County</h3>
                  <h5>Board of Health</h5>
                </a>
                <p>Campaign Encourages Masks, Cleanliness in Local Businesses</p>
                <span className="link-arrow"><a href="#" target="_blank">Read More</a></span>
              </div>
              */}
              <div className="column">
                <a href="https://themedq.com/maskofwellness/" target="_blank" className="press-logo no-border-bottom">
                  <img src={pressSix} alt="MedQuarter Regional Medical District" className="press-logo" style={{marginTop: "10px"}} />
                </a>
                <p>The MedQuarter Regional Medical District has joined forces with ‘Mask of Wellness’.</p>
                <span className="link-arrow"><a href="https://themedq.com/maskofwellness/" target="_blank">Read More</a></span>
              </div>

              <div className="column">
                <a href="https://www.telegraphherald.com/coronavirus/article_a7907d4d-7c6a-5da2-8672-42c546d1b787.html" target="_blank" className="press-logo no-border-bottom">
                  <img src={pressSeven} alt="Corridor Business Journal" className="press-logo"/>
                </a>
                <p>Dubuque County board promotes Mask of Wellness program to prevent further COVID-19 spread</p>
                <span className="link-arrow"><a href="https://www.telegraphherald.com/coronavirus/article_a7907d4d-7c6a-5da2-8672-42c546d1b787.html" target="_blank">Read More</a></span>
              </div>

              <div className="column">
                <br />
                <a href="https://littlevillagemag.com/iowa-city-doctors-launch-mask-of-wellness/" target="_blank" className="press-logo no-border-bottom">
                  <img src={pressNine} alt="Little Village" className="press-logo"/>
                </a>
                <p>Doctors launch COVID-19 safety program for businesses nationwide</p>
                <span className="link-arrow"><a href="https://littlevillagemag.com/iowa-city-doctors-launch-mask-of-wellness/" target="_blank">Read More</a></span>
              </div>

            </div>

            <div className="columns">
              <div className="column">
                <div class="anchor-border">
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column is-8 center-block">
                <h3 className="has-text-centered">Media Kit</h3>
                <p className="has-text-centered">Join our grassroots effort by spreading the message. Please download and share from our full press and media kit.
                </p>
                <p>
                  <a href="../downloads/pressmediakit.zip" className="has-text-centered">
                    <button className="button btn-kit">
                      Download
                    </button>
                  </a>
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Typography
